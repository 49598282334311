import Markdown from "markdown-to-jsx";
import SyntaxHighlighter from "react-syntax-highlighter";
import { qtcreatorDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import "./Home.css";
import "./fonts.css";
import "./Channel.css";
import "./Animation.css";
import "./AboutMe.css";
import "./BlogPost.css";
import React, { useState, useRef, useEffect } from 'react';

// For centering the table elements
const TableElement = (props) => {
  const centeredStyle = {
    textAlign: "center", // Horizontally center the content
    verticalAlign: "middle", // Vertically center the content
    paddingLeft: "1vh",
    paddingRight: "1vh",
    fontSize: "1.75vh"
  };

  return <td style={centeredStyle}>{props.children}</td>;
};

const TableHeader = (props) => {
  const centeredStyle = {
    textAlign: "center", // Horizontally center the content
    verticalAlign: "middle", // Vertically center the content
    paddingLeft: "1vh",
    paddingRight: "1vh",
    background: "rgba(50, 50, 50, 0.5)",
    fontSize: "2.25vh"
  };

  return <td style={centeredStyle}>{props.children}</td>;
};

// For centering the table in markdown
const CenteredTable = (props) => {
  const centeredStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={centeredStyle}>
      <table>{props.children}</table>
    </div>
  );
};

// This is used for centering images in the markdown blog
const CustomImage = ({ alt, src, title }) => {
  // Function to extract filename from URL
  const getFilename = (url) => {
    const cleanUrl = url.split('?')[0];
    const parts = cleanUrl.split('/');
    const filename = parts[parts.length - 1];
    return decodeURIComponent(filename);
  };

  const filename = getFilename(src);

  return (
    <div className="winxp-window-container">
      <div className="winxp-window">
        <div className="winxp-title-bar">
          <div className="winxp-title">
            <img src="/favicon.ico" alt="favicon" className="winxp-favicon" />
            <span className="winxp-filename">{filename}</span>
          </div>
          <div className="winxp-buttons">
            <span className="winxp-button winxp-minimize"></span>
            <span className="winxp-button winxp-maximize"></span>
            <span className="winxp-button winxp-close"></span>
          </div>
        </div>
        <div className="winxp-content">
          <img
            alt={alt}
            src={src}
            title={title}
          />
        </div>
      </div>
    </div>
  );
};

// This is for syntax highlighting, for some reason it is a bit broken so I modified the markdown code type definition slightly
const CodeBlock = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const codeRef = useRef(null);
  
  const lines = children.split('\n');
  const language = lines[0].startsWith('-') ? lines[0].slice(1).trim() : 'text';
  const code = lines[0].startsWith('-') ? lines.slice(1).join('\n') : children;
  
  const isLong = lines.length > 10;

  useEffect(() => {
    if (codeRef.current) {
      setIsScrollable(codeRef.current.scrollHeight > 200);
    }
  }, [code]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className={`code-block ${isLong ? 'long-code' : ''} ${isScrollable ? 'scrollable' : ''}`}>
      {isLong && (
        <button className="code-toggle" onClick={toggleExpand}>
          {isExpanded ? 'Collapse Code' : 'Expand Code'}
        </button>
      )}
      <div className={`code-content ${isExpanded ? 'expanded' : ''}`} ref={codeRef}>
        <SyntaxHighlighter
          language={language}
          wrapLongLines={true}
          style={qtcreatorDark}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

function MarkdownTOC({ markdownContent }) {
  const [toc, setToc] = useState(null);

  useEffect(() => {
    function generateTOC() {
      try {
        // Regular expression to find Markdown headers (e.g., ## Header)
        const headerRegex = /^(#+)\s+(.*)/gm;
        const headers = [];
        let match;

        // Remove content inside code blocks (between triple backticks ``` or ~~~)
        let contentWithoutCodeBlocks = markdownContent.replace(
          /```[\s\S]*?```|~~~[\s\S]*?~~~/g,
          ""
        );

        // Iterate through Markdown content to extract headers
        while ((match = headerRegex.exec(contentWithoutCodeBlocks)) !== null) {
          const level = match[1].length; // Determine header level based on number of # characters
          let text = match[2]; // Extract header text

          if (level < 4) {
            // Generate a unique identifier for the header (used as the href in the TOC)
            const id = text
              .toLowerCase()
              .replace(/\s+/g, "-") // Convert spaces to hyphens for the href
              .replace(/\?/g, "") // Remove question marks from href
              .replace(/&/g, "");
              

            // Create the href for the header by removing periods, underscores, and asterisks
            const href = `#${id
              .replace(/\./g, "-")
              .replace(/_/g, "")
              .replace(/:/g, "")
              .replace(/&/g, "")
              .replace(/\//g, "")
              .replace(/\*/g, "")}`;

            text = text.replace(/\*/g, "");

            console.log(id, href);

            // Store the header information in an array
            headers.push({ level, text, href });
          }
        }

        // Create the table of contents from the extracted headers
        const tocItems = headers.map((header) => (
          <div
            key={header.text}
            style={{
              marginLeft: `${header.level - 1}vw`, // Adjust indentation based on header level
              fontSize: `${1.7 - 0.25 * header.level}em`, // Adjust font size based on header level
            }}
          >
            <a href={header.href}>{header.text}</a>
          </div>
        ));

        // Set the generated table of contents in the component's state
        setToc(tocItems);
      } catch (error) {
        console.error("Error generating TOC", error);
      }
    }

    // Call the function to generate the table of contents when markdownContent changes
    generateTOC();
  }, [markdownContent]);

  // Render the table of contents
  return (
    <div className="toc-parent">
      <div className="toc-container">{toc}</div>
    </div>
  );
}

const BlogPostRender = ({ blogPost }) => {
  const [wordCount, setWordCount] = useState(0);

  const formattedDate = blogPost.date
    ? `${blogPost.date.getDate()}-${
        blogPost.date.getMonth() + 1
      }-${blogPost.date.getFullYear()}`
    : "";

  useEffect(() => {
    const countWords = (content) => {
      // Remove code blocks
      const contentWithoutCode = content.replace(/```[\s\S]*?```/g, '');
      
      // Remove inline code
      const contentWithoutInlineCode = contentWithoutCode.replace(/`[^`\n]+`/g, '');

      // Remove HTML tags
      const contentWithoutHtml = contentWithoutInlineCode.replace(/<[^>]*>/g, '');

      // Count words
      const words = contentWithoutHtml.match(/\b[\w']+\b/g);
      return words ? words.length : 0;
    };

    setWordCount(countWords(blogPost.content));
  }, [blogPost.content]);

  useEffect(() => {
    function adjustColumnHeight() {
      const leftCell = document.querySelector(".summary-container");
      const rightCell = document.querySelector(".right-cell");

      if (leftCell && rightCell) {
        rightCell.style.height = leftCell.offsetHeight + "px";
      }
    }

    adjustColumnHeight();
    window.addEventListener("resize", adjustColumnHeight);

    return () => {
      window.removeEventListener("resize", adjustColumnHeight);
    };
  }, []);

  return (
    <div className="fade-in-blogpost">
      <div className="markdown-content">
        <div className="blog-title-selected">{blogPost.title}</div>
        <div className="horizontal-line" />
        <div className="grid-container">
          <div className="grid-item left-cell">
            <div className="summary-container">
              <div className="blog-tagline-selected">{blogPost.tagline}</div>
              {blogPost.tags.length > 0 && (
                <div className="blog-post-tags">
                  <ul className="blog-post-tag-grid">
                    {blogPost.tags.map((tag, index) => (
                      <li key={index} className="blog-post-tag">
                        {tag}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="blog-word-count-centered">Word count: {wordCount}</div>
            </div>
          </div>
          <div className="grid-item right-cell">
            <div className="TOC-title">📖 Contents</div>
            <MarkdownTOC markdownContent={blogPost.content} />
          </div>
        </div>

        <div className="horizontal-line" />

        <Markdown
          options={{
            overrides: {
              img: {
                component: CustomImage,
              },
              code: {
                component: CodeBlock,
              },
              td: {
                component: TableElement,
              },
              th: {
                component: TableHeader,
              },
              table: {
                component: CenteredTable,
              },
            },
          }}
        >
          {blogPost.content}
        </Markdown>
        <div className="horizontal-line"></div>
        <div className="date-selected">Last updated: {formattedDate}</div>
      </div>
    </div>
  );
};

export default BlogPostRender;
