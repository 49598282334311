import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import BlogList from "./BlogList.js";
import { getState, setState } from "./stateManager.js";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./Home.css";
import "./fonts.css";
import "./Channel.css";
import "./Animation.css";
import "./AboutMe.css";
import "./BlogPost.css";

// Image array for channel definitions
const images = [
  {
    type: "bloglist",
    url: "camera_channel.jpg",
    text: "Camera Hacking",
    channel: "camera",
    summary: "This project involves reverse engineering a cheap IoT camera, while also being on the hunt for bugs.",
  },
  {
    type: "bloglist",
    url: "action_cam_channel.png",
    text: "Action Camera Hacking",
    channel: "body_cam",
    summary: "Why on Earth does an action camera need a WiFi hotspot?!?",
  },
  {
    type: "bloglist",
    url: "travel_router_channel.png",
    text: "Travel Router Hacking",
    channel: "travel_router",
    summary: "This channel is dedicated to hacking cheap travel routers.",
  },
  {
    type: "bloglist",
    url: "router_channel.jpg",
    text: "Router Hacking",
    channel: "router",
    summary: "In this project, I purchase a cheap router off of Aliexpress with the aim of remote code execution over LAN.",
  },
  {
    type: "bloglist",
    url: "nas_channel.jpg",
    text: "NAS Hacking",
    channel: "nas",
    summary: "In this project, I take a look at a moderately priced (for Aliexpress) NAS.",
  },
  {
    type: "bloglist",
    url: "vr_channel.jpg",
    text: "Vulnerability Research",
    channel: "vr",
    summary:
      "This channel is for all things VR - mitigations, exploit techniques, cool n-days, or small projects I've done that fit in a single blog!",
  },
  {
    type: "bloglist",
    url: "cd_channel.jpg",
    text: "Coding",
    channel: "cd",
    summary:
      "This channel documents some of the interesting coding projects I've worked on.",
  },
  {
    type: "bloglist",
    url: "co_channel.jpg",
    text: "Consoles",
    channel: "co",
    summary:
      "I like to repair/mod consoles in my spare time, the blogs in here are a result of these projects.",
  },
  {
    type: "bloglist",
    url: "ai_channel.jpg",
    text: "Artifical Intelligence",
    channel: "ai",
    summary: "\"The AI does not hate you, nor does it love you, but you are made out of atoms which it can use for something else.\" - Eliezer Yudkowsky",
  },
  {
    type: "game",
    url: "vaporracer.png",
    text: "VaporRacer",
    channel: "vaporracer",
    summary: "",
  },
  {
    type: "bloglist",
    url: "empty.jpg",
    text: "",
    channel: "",
    summary: "",
  },
  {
    type: "bloglist",
    url: "empty.jpg",
    text: "",
    channel: "",
    summary: "",
  }
];

// Converts the date to wii format
function formatDate(date) {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const day = daysOfWeek[date.getDay()];
  const formattedDate = `${day} ${date.getDate()}/${date.getMonth() + 1}`;
  return formattedDate;
}

// Converts the time to wii format
function formatTime(date) {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}

// This is an element in the channel grid, game/bloglist
const ChannelGridItem = ({ selected, image, index, handleClick }) => {
  if (image.type === "bloglist") {
    // If type is bloglist, normal title
    return (
      <div
        className={`channel-grid-item ${selected ? "selected" : ""}`}
        onClick={(event) => handleClick(index, event)}
      >
        <img src={`/assets/channels/${image.url}`} alt={`${index + 1}`} />
        <div className="text">{image.text}</div>
      </div>
    );
  } else if (image.type === "game") {
    // If type is game, add game icon to it
    return (
      <div
        className={`channel-grid-item ${selected ? "selected" : ""}`}
        onClick={(event) => handleClick(index, event)}
      >
        <img src={`/assets/channels/${image.url}`} alt={`${index + 1}`} />
        <div className="text">{image.text + " 🕹️"}</div>
      </div>
    );
  }
};

const BlogInterface = ({ setState, channel }) => {
  const navigate = useNavigate();

  const handleWiiMenuButtonClick = () => {
    setState(getState() - 1);
    if (getState() === 0) {
      const another_container = document.querySelector(".overlay");
      another_container.classList.add("fade-out-slow");
      const container = document.querySelector(".zoom_in_animation");
      container.classList.add("zoom-out-animation");
      setTimeout(function () {
        navigate("/");
      }, 500);
    } else if (getState() === 1) {
      const markdown = document.querySelector(".markdown-content");
      if (markdown !== null) {
        markdown.classList.add("fade-out-slow");
      }
      setTimeout(function () {
        const overlay = document.querySelector(".bloglist_overlay");
        overlay.classList.remove("fade-out-slow");
        navigate(`/${channel}`);
        overlay.classList.add("fade-in");
      }, 500);
    }
  };

  return (
    <div className="wii_menu_button" onClick={handleWiiMenuButtonClick}></div>
  );
};

const Border = () => {
  const navigate = useNavigate();

  const handleMiiButtonClick = () => {
    navigate("/mii");
  };

  const handleMsgButtonClick = () => {
    navigate("/contact");
  };

  return (
    <div className="navigation_border">
      <div className="navigation">
        <div className="wii_button_container">
          <div className="wii_button" onClick={handleMiiButtonClick}></div>
        </div>
        <div className="msg_button_container">
          <div className="msg_button" onClick={handleMsgButtonClick}></div>
        </div>
      </div>
    </div>
  );
};

const WiiDate = () => {
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const date = new Date();
      setCurrentDate(formatDate(date));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <div className="date">{currentDate}</div>;
};

const WiiTime = () => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const date = new Date();
      setCurrentTime(formatTime(date));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <div className="time">{currentTime}</div>;
};

// // Component for loading the content from about-me.md and displaying it
const AboutMe = () => {
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarkdownContent = async () => {
      try {
        const markdown = await import("./about-me.md"); // Replace with the correct path to your 'about-me.md' file
        const response = await fetch(markdown.default);
        const content = await response.text();
        setContent(content);
      } catch (error) {
        console.error("Error fetching Markdown content:", error);
      }
    };

    fetchMarkdownContent();
  }, []);

  const handleAboutMeBackButtonPress = () => {
    const container = document.querySelector(".slide-up");
    container.classList.add("slide-down");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  return (
    <div className="about-me-overlay">
      <div className="about-me-element-container">
        <div className="slide-up">
          <div
            className={"back-button"}
            onClick={handleAboutMeBackButtonPress}
          ></div>
          <div className={"about-me-background"}>
            <div className="about-me-element-container">
              <div className="about-me-title">👾 About Me</div>
              <div className="about-me-markdown">
                <Markdown>{content}</Markdown>
              </div>
            </div>
          </div>
          <div className={"mii-text"}></div>
        </div>
      </div>
    </div>
  );
};

// Component for loading the content from about-me.md and displaying it
const Contact = () => {
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarkdownContent = async () => {
      try {
        const markdown = await import("./about-me.md"); // Replace with the correct path to your 'about-me.md' file
        const response = await fetch(markdown.default);
        const content = await response.text();
        setContent(content);
      } catch (error) {
        console.error("Error fetching Markdown content:", error);
      }
    };

    fetchMarkdownContent();
  }, []);

  const handleBackButtonPress = () => {
    const container = document.querySelector(".slide-up");
    container.classList.add("slide-down");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  return (
    <div className="about-me-overlay">
      <div className={`slide-up`}>
        <div className={"back-button"} onClick={handleBackButtonPress}></div>
        <div className={"about-me-background"}></div>
        <div className={"mii-text"}></div>
        <div className="contact-me-title">☎️ Contact Me</div>
        <div className="contact-grid-wrapper">
          <div className="contact-grid">
            <a href="https://www.github.com/lr-m/" className="github-button">
              {" "}
            </a>
            <a href="mailto:luke.mills.35@outlook.com" className="email-button">
              {" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

function Grid({ setClickX, setClickY, setState }) {
  const navigate = useNavigate();

  const handleSquareClick = (index, event, channel) => {
    const clickedSquare = event.currentTarget;

    // Ensure clickedSquare is a valid DOM element
    if (!clickedSquare) {
      console.error("Clicked square is not a valid DOM element.");
      return;
    }

    // Calculate the center coordinates based on the dimensions of the square element
    const { width, height, left, top } = clickedSquare.getBoundingClientRect();
    const centerX = left + width / 2;
    const centerY = top + height / 2;

    // Set the click coordinates as the center coordinates
    setClickX(centerX - window.innerWidth / 2);
    setClickY(centerY - window.innerHeight / 2);

    setState(1);

    navigate(`/${channel}`);
  };

  return (
    <div className="channel-grid">
      {images.map((image, index) => (
        <ChannelGridItem
          key={index}
          selected={false}
          image={image}
          index={index}
          handleClick={(index, event) =>
            handleSquareClick(index, event, image.channel)
          }
        />
      ))}
    </div>
  );
}

// Main app component
function App() {
  const [clickX, setClickX] = useState(0);
  const [clickY, setClickY] = useState(0);

  useEffect(() => {
    document.title = "Luke's Blog";
  }, []);

  const rootStyles = {
    "--click_x": `${clickX}px`,
    "--click_y": `${clickY}px`,
  };

  window.addEventListener("popstate", function (event) {
    // The event object contains information about the navigation
    if (event.state) {
      if (window.location.href.endsWith(".md")) {
        setState(2);
        return;
      }
      images.forEach((image, index) => {
        if (window.location.href.endsWith(images[index].channel)) {
          setState(1);
          const overlay = document.querySelector(".bloglist_overlay");
          if (overlay !== null) {
            overlay.classList.remove("fade-out-slow");
            overlay.classList.add("fade-in");
          }
          return;  // While not necessary here, this ensures the iteration stops
        }
      });
    }
  });

  return (
    <BrowserRouter>
      <Grid setClickX={setClickX} setClickY={setClickY} setState={setState} />

      <Routes>
        <Route
          basename={process.env.PUBLIC_URL}
          path={`/mii`}
          element={<AboutMe />}
        />
        <Route
          basename={process.env.PUBLIC_URL}
          path={`/contact`}
          element={<Contact />}
        />

        {images
          .filter((image) => image.channel !== "") // Filter images with a non-empty channel
          .map((image, index) => (
            <Route
              basename={process.env.PUBLIC_URL}
              key={images[index].channel}
              exact
              path={`/${images[index].channel}/*`}
              element={
                <div className="overlay">
                  <style>
                    {`:root { ${Object.entries(rootStyles)
                      .map(([key, value]) => `${key}:${value};`)
                      .join("")} }`}
                  </style>
                  <div className="zoom_in_animation">
                    <BlogList
                      type={images[index].type}
                      name={images[index].text}
                      channel={images[index].channel}
                      summary={images[index].summary}
                      backgroundImage={`/assets/channels/${images[index].url}`}
                    />
                    <BlogInterface
                      setState={setState}
                      channel={images[index].channel}
                    />
                    {window.location.href.endsWith(images[index].channel) &&
                      setState(1)}
                    {window.location.href.replace(/#.*/, "").endsWith(".md") &&
                      setState(2)}
                  </div>
                </div>
              }
            />
          ))}
        <Route basename={process.env.PUBLIC_URL} path="/" element={<div />} />
      </Routes>
      <Border />
      <WiiDate />
      <WiiTime />
    </BrowserRouter>
  );
}

export default App;
