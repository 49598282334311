import React, { useEffect } from "react";
import BlogPostRender from "./BlogPost.js";
import "./Home.css";
import "./fonts.css";
import "./Channel.css";
import "./Animation.css";
import "./AboutMe.css";
import "./BlogPost.css";
import { getState, setState } from "./stateManager.js";
import { Route, Routes, useNavigate } from "react-router-dom";

const BlogPostGridElement = ({ blogPost, onSelect }) => {
  const handleClick = () => {
    onSelect(blogPost);
  };

  const formattedDate = blogPost.date
    ? `${blogPost.date.getDate()}-${
        blogPost.date.getMonth() + 1
      }-${blogPost.date.getFullYear()}`
    : "";

  return (
    <div className="blog-post" onClick={handleClick}>
      <h2 className="blog-title">{blogPost.title}</h2>
      <div className="blog-details">
        <div className="blog-meta">
          {blogPost.date && <span className="blog-date">{formattedDate}</span>}
          <span className="blog-word-count">{blogPost.wordCount} words</span>
        </div>
        <p className="blog-excerpt">{blogPost.excerpt}</p>
        {blogPost.tags.length > 0 && (
          <div className="blog-tags">
            <ul className="tag-grid">
              {blogPost.tags.map((tag, index) => (
                <li key={index} className="tag">
                  {tag}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const BlogList = ({ type, name, channel, summary, backgroundImage }) => {
  const [blogPosts, setBlogPosts] = React.useState([]);
  const [selectedPost, setSelectedPost] = React.useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "bloglist") {
      const fetchBlogPosts = async () => {
        const blogPostPaths = require.context("./blogs", false);
        const blogPostPromises = blogPostPaths.keys().map(async (path) => {
          const markdown = await import(`./blogs/${path.slice(2)}`);
          const filename = path.slice(2);

          if (filename.startsWith(channel)) {
            const response = await fetch(markdown.default);
            const content = await response.text();

            const frontMatterEndIndex = content.indexOf("---", 4);
            const frontMatter = content.slice(4, frontMatterEndIndex).trim();
            const markdownContent = content
              .slice(frontMatterEndIndex, content.length)
              .trim()
              .replace("---", "")
              .trim();

            const frontMatterLines = frontMatter.split("\n");
            let parsedExcerpt = "";
            let parsedTitle = "";
            let parsedTagline = "";
            let parsedTags = [];
            let isTagSection = false;

            for (let line of frontMatterLines) {
              const colonIndex = line.indexOf(":");
              if (colonIndex !== -1) {
                const key = line.slice(0, colonIndex).trim();
                const value = line.slice(colonIndex + 1).trim();

                if (key === "excerpt") {
                  parsedExcerpt = value.replace(/"/g, "");
                } else if (key === "title") {
                  parsedTitle = value.replace(/"/g, "");
                } else if (key === "tagline") {
                  parsedTagline = value.replace(/"/g, "");
                } else if (key === "tags") {
                  isTagSection = true;
                  continue;
                }
              }

              if (isTagSection) {
                if (line === "" || line.includes(":") || line.includes("---")) {
                  isTagSection = false;
                } else {
                  const tags = line
                    .trim()
                    .split("-")
                    .map((tag) => tag.trim());
                  parsedTags.push(...tags.filter((tag) => tag !== ""));
                }
              }
            }

            const countWords = (content) => {
              // Remove code blocks
              const contentWithoutCode = content.replace(/```[\s\S]*?```/g, '');
              
              // Remove inline code
              const contentWithoutInlineCode = contentWithoutCode.replace(/`[^`\n]+`/g, '');
  
              // Remove HTML tags
              const contentWithoutHtml = contentWithoutInlineCode.replace(/<[^>]*>/g, '');
  
              // Count words
              const words = contentWithoutHtml.match(/\b[\w']+\b/g);
              return words ? words.length : 0;
            };

            return {
              path,
              content: markdownContent,
              channel: extractChannelFromFilename(filename),
              date: extractDateFromFilename(filename),
              filename: filename,
              excerpt: parsedExcerpt,
              title: parsedTitle,
              tagline: parsedTagline,
              tags: parsedTags,
              wordCount: countWords(markdownContent),
            };
          }

          return null;
        });

        const blogPosts = await Promise.all(blogPostPromises);
        const sortedBlogPosts = blogPosts
          .filter(Boolean)
          .sort((a, b) => b.date - a.date);

        setBlogPosts(sortedBlogPosts);
      };

      fetchBlogPosts();
    }
  }, [channel, type]);

  const extractChannelFromFilename = (filename) => {
    const parts = filename.split("-");
    return parts[0];
  };

  const extractDateFromFilename = (filename) => {
    const dateRegex = /\d{4}-\d{2}-\d{2}/;
    const match = filename.match(dateRegex);

    if (match) {
      const [year, month, day] = match[0].split("-");
      return new Date(year, month - 1, day);
    }

    return null;
  };

  const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  const handleBlogPostSelect = (blogPost) => {
    setState(getState() + 1);
    const overlay = document.querySelector(".bloglist_overlay");
    overlay.classList.add("fade-out-slow");
    overlay.classList.remove("fade-in");

    setTimeout(function () {
      setSelectedPost(blogPost);
      navigate(
        `/${blogPost.channel}/${blogPost.filename.replace(
          /^[^-]+-[\d]{4}-[\d]{2}-[\d]{2}-/,
          ""
        )}`
      );
    }, 300);
  };

  if (type === "bloglist") {
    return (
      <div className="blogList_container" style={containerStyle}>
        <div className="bloglist_overlay">
          {getState() === 1 && (
            <>
              <h1 className="blogList_title">{name}</h1>
              <h1 className="blogList_summary">{summary}</h1>
              <div className="blogPost_grid">
                {blogPosts.map((blogPost, index) => (
                  <BlogPostGridElement
                    key={index}
                    blogPost={blogPost}
                    onSelect={handleBlogPostSelect}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        <Routes>
          {blogPosts.map((blogPost, index) => (
            <Route
              basename={process.env.PUBLIC_URL}
              key={index}
              path={`/${blogPost.filename.replace(
                /^[^-]+-[\d]{4}-[\d]{2}-[\d]{2}-/,
                ""
              )}`}
              element={<BlogPostRender blogPost={blogPost} />}
            />
          ))}
        </Routes>
      </div>
    );
  } else if (type === "game") {
    return (
      <div className="blogList_container" style={containerStyle}>
        <div className="game_container">
          <iframe
            src={"./VaporRacer.html"}
            className="borderless-iframe"
            title="VaporRacer"
          ></iframe>
        </div>
      </div>
    );
  }
};

export default BlogList;